import React from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
export default function Intro({ user }) {
  return (
    <>
      <section className="hero">
        <div className="hero__bg">
          <img
            src={process.env.PUBLIC_URL + "/images/ces/hero.jpg"}
            alt="hero"
          />
        </div>
        <div className="auto__container">
          <div className="hero__inner">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    data-aos="fade-up"
                    data-aos-delay={400}
                    className="aos-init aos-animate"
                  >
                    <motion.div
                      animate={{
                        scale: [0, 1.1, 1],
                        opacity: [0, 1],
                      }}
                    >
                      <div className="hero-container">
                        {user && user.firstName ? (
                          <h1 className="greeting">
                            <span className="text-white">
                              Hi {user.firstName},
                            </span>
                          </h1>
                        ) : null}
                        <h1>
                          <span className="text-white">
                            Exploring Industrial
                            <br /> Business Transformation?
                          </span>
                        </h1>
                        {/* <div
                          data-aos="fade-up"
                          data-aos-delay={800}
                          className="aos-init aos-animate"
                        >
                          <div className="hero-btns">
                            <div className="hero-btn">
                              <a
                                href="#portfolio"
                                type="button"
                                className="button btn-std pink"
                              >
                                VIEW PORTFOLIO
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </motion.div>
                  </div>
                </div>
                {/* <h1>
                      <span className="text-white">
                        Join Landmark Ventures @ CES 2024
                      </span>
                    </h1>
                    <p>
                      <span className="text-light">
                        We are pleased to host our annual LMV @ CES event
                        series, custom built for our robust network of Fortune
                        500 CMOs, Chief Digital Officer, Venture Capitalists,
                        Entrepreneurs, and Brand Executives across the digital
                        marketing, eCommerce, advertising, sports, social
                        impact, Web3, and media ecosystems.
                      </span>
                    </p>
                  </div>
                  {" "} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container narrower">
        <div className="cap-width">
          <div className="row hero-row-lower">
          </div>
        </div>
      </div> */}
    </>
  );
}
