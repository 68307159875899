import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Intro from "./sections/Intro";
import Join from "./sections/Join";
import Company from "./sections/Company";
import Request from "./sections/Request";
import Event from "./sections/Event";
import Partner from "./sections/Partner";
import Head from "../Base/Head";

export default function UNGA() {
  const head = {
    title: "SIS @ UNGA 2024 - Landmark Ventures",
    description:
      "Social Innovation Summit, along with our partners, are delighted to host a series of VIP events for our network of corporate responsibility executives, philanthropists, investors, nonprofit leaders, policymakers, celebrity activists, and social entrepreneurs across the global impact ecosystem during UN General Assembly week 2024!",
    url: "https://unga.landmarkventures.com",
    image: "https://i.imgur.com/wfcfukI.jpeg",
  };

  return (
    <>
      <Head head={head} />
      <div className="events-wrapper unga-wrapper redesign">
        <Intro />
        {/* <Join /> */}
        {/* <Event /> */}
        <Request />
        {/* <Company /> */}
        {/* <Partner /> */}
      </div>
    </>
  );
}
