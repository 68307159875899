import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Analytics } from "@vercel/analytics/react";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Events from "./Events/Events";
import EventsDynamic from "./EventsDynamic/EventsDynamic";
import OurEvents from "./OurEvents/OurEvents";
import CES from "./CES/CES";
import UNGA from "./UNGA/UNGA";
import IndustrialTech from "./IndustrialTech/IndustrialTech";
import IndustrialTechNew from "./IndustrialTechNew/IndustrialTechNew";
import IsraelDealPortal from "./IsraelDealPortalNew/IsraelDealPortalNew";
import DealmakersIB from "./DealmakersIB/DealmakersIB";
import SisOmni from "./SisOmni/SisOmni";
import SisDelegate from "./SisDelegate/SisDelegate";
import LeadGen from "./LeadGen/LeadGen";
import LeadMagic from "./LeadMagic/LeadMagic";
import { setCookie } from "./utils";
import ApiService from "./services/ApiService";
import LoadingPage from "./Base/Loading";

export default function App() {
  // const homepage = process.env.REACT_APP_HOMEPAGE || "dynamic"; // temp should be ""
  const homepage = process.env.REACT_APP_HOMEPAGE || "";
  const [leadPage, setLeadPage] = useState(false);
  const [changeHead, setChangeHead] = useState(false);
  const [urlParams, setUrlParams] = useState(null);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});
  const [dynamicEvent, setDynamicEvent] = useState(null);
  const [dynamicEventReferral, setDynamicEventReferral] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  console.log("HOMEPAGE", homepage);
  console.log(`window.location.pathname`, window.location.pathname);
  console.log(`urlParams`, urlParams);

  const isOnDynamicEventPage =
    dynamicEvent && dynamicEvent.eventId ? true : false;

  useEffect(() => {
    async function getDynamicEvents() {
      try {
        const api = new ApiService();
        const events = await api.get(`landmark/dynamicEvents/getAllEvents`);
        console.log(
          `getDynamicEvents() - landmark/dynamicEvents/getAllEvents`,
          events
        );
        if (events && events.length) {
          const fullPageUrl = location.pathname.slice(1).toLowerCase().trim(); // remove leading /
          let pageUrl;
          let referral;
          try {
            pageUrl = fullPageUrl.split("/")[0];
            referral = fullPageUrl.split("/")[1];
          } catch (error) {}
          const event = events.find(
            (e) => e.pageUrl.toLowerCase().trim() === pageUrl
          );
          if (event) {
            // dynamic event for this page url has been found
            setDynamicEvent(event);
            console.log(`Dynamic Event for this page url has been found`);
            console.log(`setDynamicEvent()`, event);

            if (referral) {
              // console.log(`possible referral detected - ${referral}`);
              // possible referral url - check event to see if the referral url is included
              if (event.referralUrls && event.referralUrls.length) {
                const eventReferral = event.referralUrls.find(
                  (r) =>
                    r.url.toLowerCase().trim() === referral.toLowerCase().trim()
                );
                if (eventReferral) {
                  console.log(`referral: ${eventReferral.name}`);
                  setDynamicEventReferral({
                    url: eventReferral.url,
                    name: eventReferral.name,
                  });
                }
              }
            } else {
              // console.log(`no referral detected`);
            }
          }
          // setUser(response);
        } else {
          throw `Couldn't get dynamic events`;
        }
      } catch (error) {
        console.log(`error`, error);
      } finally {
        setLoading(false);
      }
    }

    // user is on events client (or running locally!) & pathname is not empty (page is not index)
    if (!process.env.REACT_APP_HOMEPAGE && location.pathname.length > 1) {
      console.log(
        `User is on events client and page is not index (${location.pathname}) - getting dynamic events`
      );
      getDynamicEvents();
    } else {
      // not loading dynamic page
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    try {
      // Get the current URLSearchParams object from the window.location
      const params = new URLSearchParams(window.location.search);

      // Create an empty object to store the parsed parameters
      const parsedParams = {};

      // Loop through all the URL parameters and store them in the parsedParams object
      for (const [key, value] of params.entries()) {
        parsedParams[key] = value;
      }

      // set cookies
      const userId = parsedParams["u"];
      if (userId) {
        console.log(`setting userId cookie`, userId);
        setCookie("userId", userId);
        setUserId(userId);
      }

      // Set the parsed parameters in the state
      setUrlParams(parsedParams);
    } catch (error) {
      console.log(`error`, error);
    }
  }, []);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const api = new ApiService();
        const response = await api.get(
          `landmark/contacts/getContact/${userId}`
        );
        console.log(
          `getUserInfo() - landmark/contacts/getContact/${userId}`,
          response
        );
        if (response && response.contactId) {
          setUser(response);
        } else {
          throw `Couldn't get user from userId ${userId}`;
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    }
    if (userId) {
      getUserInfo();
    }
  }, [userId]);

  // Render the component based on the value of `HOMEPAGE` env variable
  const renderHomepage = () => {
    if (homepage === "industrialtech") {
      return <IndustrialTech />;
    } else if (homepage === "industrial") {
      return <IndustrialTechNew user={user} />;
    } else if (homepage === "dealmakersib") {
      return <DealmakersIB />;
    } else if (homepage === "israeldealportal") {
      return <IsraelDealPortal />;
    } else if (homepage === "sisomni") {
      return <SisOmni />;
    } else if (homepage === "sisdelegate") {
      return <SisDelegate />;
    } else if (homepage === "ces") {
      return <CES />;
    } else if (homepage === "unga") {
      return <UNGA />;
    } else if (homepage === "leadgen") {
      return <LeadGen />;
    } else if (homepage === "leadmagic") {
      return <LeadMagic />;
    } else if (homepage === "ourevents") {
      return <OurEvents />;
    }
    // events.landmarkventures.com homepage (also for dynamic)
    else {
      return <Events />;
    }
  };
  let shouldShowHeader = true;
  let shouldShowFooter = true;
  let headerProps = {
    sticky: true,
    transparent: false,
    cssClass: "position-fixed",
  };

  const isOnPage = (page) => {
    if (
      window.location.pathname.includes(page.toLowerCase()) ||
      homepage === page.toLowerCase()
    ) {
      return true;
    }
    return false;
  };

  // const isOnDynamicPage = (page) => {
  //   if (
  //     window.location.pathname.includes(page.toLowerCase()) ||
  //     homepage === page.toLowerCase()
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };
  // this checks the env variables to determine which page is being rendered
  // let shouldShowHeaderFooter =
  //   homepage !== "sisdelegate" && homepage !== "leadgen";
  // however when running locally env variables may not be set, in which case check pathname
  if (
    isOnPage("sisdelegate") ||
    isOnPage("leadgen") ||
    isOnPage("unga") ||
    isOnDynamicEventPage
  ) {
    shouldShowHeader = false;
    shouldShowFooter = false;
  }
  if (
    isOnPage("ces") ||
    isOnPage("israeldealportal") ||
    isOnPage("industrial")
  ) {
    shouldShowHeader = true;
    shouldShowFooter = false;
    headerProps = { transparent: true, cssClass: "ces-header", sticky: false };
  }

  console.log(`shouldShowHeader`, shouldShowHeader);
  console.log(`shouldShowFooter`, shouldShowFooter);

  if (loading) {
    return <LoadingPage />; // Display loading component while fetching data
  }

  return (
    <>
      <Toaster
        containerStyle={{
          top: 100,
        }}
        toastOptions={{
          style: {
            background: "#175d8c",
            color: "#fff",
          },
        }}
      />
      {shouldShowHeader && <Header headerProps={headerProps} />}
      <div
        className={
          "main " + (leadPage || isOnDynamicEventPage ? "removeP" : "")
        }
      >
        <Routes>
          <Route path="" element={renderHomepage()} />
          <Route path="events" element={<Events />} />
          <Route path="ourevents" element={<OurEvents />} />
          <Route path="ces" element={<CES />} />
          <Route path="unga" element={<UNGA />} />
          <Route path="industrialtech" element={<IndustrialTech />} />
          <Route
            path="industrial"
            element={<IndustrialTechNew user={user} />}
          />
          <Route path="dealmakersib" element={<DealmakersIB />} />
          <Route path="israeldealportal" element={<IsraelDealPortal />} />
          <Route path="sisomni" element={<SisOmni />} />
          <Route path="sisdelegate" element={<SisDelegate />} />
          <Route
            path="leadgen1"
            element={
              <LeadGen
                setChangeHead={setChangeHead}
                setLeadPage={setLeadPage}
              />
            }
          />
          <Route
            path="leadgen2"
            element={
              <LeadMagic
                setChangeHead={setChangeHead}
                setLeadPage={setLeadPage}
              />
            }
          />
          {/* dynamic events */}
          {dynamicEvent ? (
            <Route
              path={dynamicEvent.pageUrl}
              element={<EventsDynamic event={dynamicEvent} />}
            />
          ) : null}
          {/* dynamic event with referral */}
          {dynamicEvent && dynamicEventReferral && dynamicEventReferral.url ? (
            <Route
              path={`${dynamicEvent.pageUrl}/${dynamicEventReferral.url}`}
              element={
                <EventsDynamic
                  event={dynamicEvent}
                  referral={dynamicEventReferral}
                />
              }
            />
          ) : null}
        </Routes>
      </div>
      <Analytics />
      {shouldShowFooter && <Footer />}
    </>
  );
}
