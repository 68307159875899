import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Intro from "./sections/Intro";
import Join from "./sections/Join";
import Company from "./sections/Company";
import Request from "./sections/Request";
import Event from "./sections/Event";
import Partner from "./sections/Partner";
import Head from "../Base/Head";

export default function IndustrialTechNew({ user }) {
  console.log(`Industrial - user`, user);
  const head = {
    title: "Industrial Tech - Landmark Ventures",
    description:
      "Welcome to the Industrial Technology Practice at Landmark Ventures. We drive strategic business transformation across industrial operations, equipment, and services.",
    url: "https://industrialtech.landmarkventures.com",
    image: "https://i.imgur.com/Uu9JI91.jpeg",
  };

  return (
    <>
      <Head head={head} />
      <div className="events-wrapper ces-wrapper redesign israel-dealportal new redesigned industrial-tech-new specificity-adder-1 specificity-adder-2">
        <Intro user={user} />
        {/* <Join /> */}
        {/* <Event /> */}
        <Request user={user} />
        {/* <Company /> */}
      </div>
    </>
  );
}
