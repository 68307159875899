import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Intro from "./sections/Intro";
import Join from "./sections/Join";
import Company from "./sections/Company";
import Request from "./sections/Request";
import Event from "./sections/Event";
import Partner from "./sections/Partner";
import Head from "../Base/Head";

export default function CES() {
  const head = {
    title: "Israel Deal Portal - Landmark Ventures",
    description:
      "Landmark maintains deep roots supporting the tech ecosystem in Israel, with our founding team hailing from the country, longstanding offices in Tel Aviv and Raanana, and a rich history of engagement with the Israel startup and investment ecosystem, boasting over 1,000 successful clients, investors, and partners in Israel.",
    url: "https://israeldealportal.landmarkventures.com",
    image: "https://i.imgur.com/62Oue8A.png",
  };

  return (
    <>
      <Head head={head} />
      <div className="events-wrapper ces-wrapper redesign israel-dealportal new">
        <Intro />
        {/* <Join /> */}
        {/* <Event /> */}
        <Request />
        {/* <Company /> */}
        {/* <Partner /> */}
      </div>
    </>
  );
}
