import ApiService from "./ApiService";

export const WebsiteAnalyticsActionsEnums = {
  VISIT: "VISIT",
  TIME_SPENT: "TIME_SPENT",
  ELEM_CLICK: "ELEM_CLICK",
};

export const updateAnalytics = async (options) => {
  let {
    contactId,
    url,
    action,
    actionData,
    apiService = null, // optional
  } = options;
  console.log(`updateAnalytics()`, options);
  try {
    const api = apiService ? apiService : new ApiService();
    const response = await api.post(
      `landmark/websiteAnalytics/updateAnalytics`,
      { contactId, url, action, actionData }
    );
    console.log(
      `updateAnalytics() - landmark/websiteAnalytics/updateAnalytics`,
      response
    );
  } catch (error) {
    console.error("Error updating analytics", error);
  }
};
