import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import {
  formatDatestring,
  getDisplayDateFromStartEndDateString,
  getNumPostfix,
  randomString,
  useScrollToLocation,
} from "../../utils";
import { toast } from "react-hot-toast";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const tag = "israeldealportal";

export default function Request() {
  const [isShown, setIsShown] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });

  useScrollToLocation();

  // companies
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const api = new ApiService();
        const response = await api.get(
          `landmark/portfolio/getCompanies/${tag}`
        );
        console.log(
          `fetchCompanies() - landmark/portfolio/getCompanies/${tag}`,
          response
        );
        if (response && response.length && response[0].companyId) {
          setCompanies(response);
        } else {
          throw "Couldn't get companies";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchCompanies();
  }, []);
  const [form, setForm] = useState({
    companies: [],
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    notes: "",
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  // optionsName - e.g. expertiseOptions
  const optionOnChange = (e, optionsName) => {
    if (e.target.checked) {
      let arr = [...form[optionsName]];
      arr.push(e.target.id);
      const obj = {};
      obj[optionsName] = arr;
      updateForm(obj);
    } else {
      const obj = {};
      obj[optionsName] = form[optionsName].filter(
        (item) => item !== e.target.id
      );
      updateForm(obj);
    }
  };

  const clearForm = () => {
    setForm({
      companies: [],
      firstName: "",
      lastName: "",
      company: "",
      jobTitle: "",
      email: "",
      notes: "",
    });
  };

  const submitForm = async () => {
    if (!formValid) {
      console.log(`submitForm() - form is invalid`, form);
      return;
    }
    console.log(`submitForm()`, form);
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/portfolio/submitForm`, {
        form,
        tag,
      });
      console.log(`submitForm() - response`, response);
      // toast.success("Your response has been logged. Thank you!");
      setIsShown(true);
      // setCompanies(response);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("An error occurred when submitting your response.");
    }
  };

  if (!companies || !companies.length) {
    return null;
  }

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const { companies, firstName, lastName, company, jobTitle, email } = form;

    if (!companies.length) {
      formErrorMsg = "Please select at least one Israeli Startup";
      return false;
    }
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  const OptionCheckbox = ({ option, optionsName }) => {
    const id = `${option}`;
    return (
      <label htmlFor={id} className="check">
        <div className="check__box">
          <input
            type="checkbox"
            id={id}
            checked={form[optionsName].some((p) => p === option)}
            onChange={(event) => optionOnChange(event, optionsName)}
          />
          <span>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
              alt="icon"
            />
          </span>
        </div>
        <div className="check-label">{option}</div>
      </label>
    );
  };

  return (
    <>
      <div className="cap-width">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 20, y: 0 }} // Set your initial state
          animate={inView ? { opacity: 1, y: 0, x: 0 } : {}} // Set your animation properties
          transition={{ duration: 0.9, ease: "easeOut" }}
        >
          <div></div>
        </motion.div>
        <div className="options-list" id="portfolio">
          <div className="option-container" style={{ minHeight: "unset" }}>
            <div
              className="option-header"
              style={{ borderRadius: "8px", minHeight: "13px" }}
            ></div>
          </div>
          <div className="option-heading-text">
            To request a meeting with Israeli startups,
            <br /> please select one or more below:
          </div>

          {companies && companies.length
            ? companies.map((item, index) => {
                return (
                  <RequestItemCompany
                    itemData={item}
                    key={index}
                    updateForm={updateForm}
                    form={form}
                    index={index}
                  />
                );
              })
            : null}
        </div>
      </div>
      <div className="cap-width slightly-wider">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 0 }} // Set your initial state
          animate={inView ? { opacity: 1, y: 0 } : {}} // Set your animation properties
          transition={{ duration: 1.4, ease: "easeOut" }}
        >
          <div className="form-container" id="join">
            <div className="requestForm">
              <h3>Your Information</h3>
              <div className="requestForm__inputs">
                <label className="input__outer sm">
                  <h5>First Name</h5>
                  <div className="input">
                    <input
                      type="name"
                      value={form.firstName}
                      onChange={onChangeInput("firstName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Last Name</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={onChangeInput("lastName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Company</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.company}
                      onChange={onChangeInput("company")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Job title</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.jobTitle}
                      onChange={onChangeInput("jobTitle")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Email</h5>
                  <div className="input">
                    <input
                      type="email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                  </div>
                </label>
                {/* <label className="input__outer sm">
                  <h5>Referred By (optional)</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.referredBy}
                      onChange={onChangeInput("referredBy")}
                    />
                  </div>
                </label> */}
                <label className="input__outer sm">
                  <h5>Tell us about your business goals (optional)</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.notes}
                      onChange={onChangeInput("notes")}
                    />
                  </div>
                </label>
              </div>
              <div className="requestForm submit">
                {!isShown && (
                  <div className="submit-wrapper">
                    <button
                      type="submit"
                      onClick={submitForm}
                      className={`button pink-btn ${submitDisabledClass}`}
                    >
                      SUBMIT
                    </button>
                    {formErrorMsg.length ? (
                      <div className="form-invalid-msg">{formErrorMsg}</div>
                    ) : null}
                  </div>
                )}
              </div>
              <AnimatePresence>
                {/* {isShown && <SuccessModal setIsShown={setIsShown} />} */}
                {isShown && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div className="submit-success-msg">
                      Thank you for your submission. One of the leaders from our
                      Israel Deal Portal Practice will be in touch shortly to
                      follow up. We look forward to connecting with you.
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      </div>
      {/* <div className="disclaimer">
        Landmark Events are not affiliated with CES®
      </div> */}
      <div style={{ height: "400px" }}></div>
    </>
  );
}

const RequestItemCompany = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.companies];
      arr.push(props.itemData);
      props.updateForm({ companies: arr });
      console.log(
        `RequestItemCompany() - form companies updated - company added`,
        arr
      );
    } else {
      const adjustedArr = props.form.companies.filter(
        (item) => item.companyId !== props.itemData.companyId
      );
      props.updateForm({
        companies: adjustedArr,
      });
      console.log(
        `RequestItemCompany() - form companies updated - company removed`,
        adjustedArr
      );
    }
  };

  let checkboxMode = true;

  const randomId = randomString(6);

  const {
    companyId,
    title,
    shortDescription,
    longDescription,
    url,
    imageUrl,
    financials,
    competitor,
    funding,
    valuation,
    majorInvestors,
    recentDeal,
    transaction,
    tags,
    owners,
    createdTime,
  } = props.itemData;

  const StartupFieldRow = (field, data, anchorMode) => {
    if (!data || !data.length) {
      return null;
    }

    return (
      <div className="row w-100 startup-row">
        <div className="col-12 col-md-3">
          <div className="startup-field">{field}:</div>
        </div>
        <div className="col-12 col-md-9">
          {anchorMode ? (
            <a href={data} target="_blank">
              <div className="startup-data">{data}</div>
            </a>
          ) : (
            <div className="startup-data">{data}</div>
          )}
        </div>
      </div>
    );
  };

  if (checkboxMode) {
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: 20, y: 0 }} // Set your initial state
        animate={inView ? { opacity: 1, y: 0, x: 0 } : {}} // Set your animation properties
        transition={{ duration: 0.9, ease: "easeOut" }}
      >
        <div className="option-container">
          <div className="req-item">
            <div className="outer-container">
              <div className={`checkbox-container bg-${props.index}`}>
                <div className="check__box adj">
                  <input
                    type="checkbox"
                    id={randomId}
                    checked={checked}
                    onChange={onChange}
                  />
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                      alt="icon"
                    />
                  </span>
                </div>
              </div>
              <div className="content-container">
                <div className="req-item-content">
                  <div className="req-item-upper">
                    <div className="row-container">
                      <div className="row w-100 startup-row top">
                        <div className="col-12 col-md-3 startup-img-col">
                          <div className="startup-img">
                            <img className="img-fluid" src={imageUrl} />
                          </div>
                        </div>
                        <div className="col-12 col-md-9">
                          <div className="short-desc-container">
                            <div className="startup-short-desc">
                              {shortDescription}
                            </div>
                          </div>
                        </div>
                      </div>
                      {StartupFieldRow("Description", longDescription)}
                      {StartupFieldRow("Website", url, true)}
                      {StartupFieldRow("Financials", financials)}
                      {StartupFieldRow("Competitor", competitor)}
                      {StartupFieldRow("Transaction", transaction)}
                      {StartupFieldRow("Recent Deal", recentDeal)}
                      {StartupFieldRow("Funding", funding)}
                      {StartupFieldRow("Valuation", valuation)}
                      {StartupFieldRow("Major Investor(s)", majorInvestors)}
                    </div>

                    {/* <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="req-item-header">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
            </div> */}
                  </div>
                  {/* <div className="req-item-lower">
        </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
  // legacy
  if (checkboxMode) {
    return (
      <div className="req-item">
        <div className="req-item-content">
          <div className="req-item-upper">
            <div className="check__box adj">
              <input
                type="checkbox"
                id={randomId}
                checked={checked}
                onChange={onChange}
              />
              <span>
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                  alt="icon"
                />
              </span>
            </div>
            <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="req-item-header">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
            </div>
          </div>
          {/* <div className="req-item-lower">
        </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="req-item">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <div className="req-item-content">
        <div className="req-item-upper">
          <div className="req-item-img">
            <img className="img-fluid" src={props.itemData.imageUrl} />
          </div>
          <div className="req-item-header">
            <h5>{props.itemData.title}</h5>
            <p className="sm short-desc">{props.itemData.shortDescription}</p>
            <p className="sm long-desc">{props.itemData.longDescription}</p>
          </div>
        </div>
        {/* <div className="req-item-lower">
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="requestFormItem">
      <div className="requestFormItem__inner">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="requestFormItem__content">
          <div className="d-flex event-container">
            <div className="company-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="event-info">
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">{props.itemData.shortDescription}</p>
              <p className="sm long-desc">{props.itemData.longDescription}</p>
              <p className="sm">{props.itemData.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
