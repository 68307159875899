import React from "react";

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <img src={process.env.PUBLIC_URL + "/images/loading-teak.gif"} />
    </div>
  );
};

export default LoadingPage;
